import { MaterialisedPropertyData, SaleDetailsType, SaleMethod } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import Decimal from 'decimal.js';
import { Predicate } from '../../../../predicate';
import { generateParentPath, getPathParentAndIndex, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';
import { buildSigningTimelines } from '../../../../util/dataExtract';
import { setDepositTime } from './setDepositTime';

const iNN = Predicate.isNotNullish;

/**Enforces Prescribed Minimum Advertising Price and 10% range on advertised price
 *
 * input only fields: sale.agentEstPrc, sale.vendorPrc, sale.saleMethod
 * input and output fields: sale.advertPrc, sale.advertPrcUpper
 * output only fields: cgtEnable, auctionFeeSwitches.applicable, hideFinance, hideSaleRequired
 */
export const advertRestrict: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState) => {
  const updatedPathSegs = normalisePathToStrArray(updatedPath);
  if (updatedPathSegs[0] !== 'sale') {
    // The only inputs we use are in the sale map, so we'll just ignore anything else
    return;
  }
  // s short for sale (structure)
  const { parent: s, indexer } = getPathParentAndIndex(updatedPath, immerProxy, true) as {parent: SaleDetailsType, indexer: keyof SaleDetailsType};
  if (!s || !indexer) {
    return;
  }
  // saleMethod is the only point not assessed as a number
  if (!(indexer === 'saleMethod' || typeof s[indexer] === 'number')) {
    return; // Do nothing. No valid actions when value is updated to nothing useful
  }

  // We perform this check here, because the user needs to be explicitly updating this value for us
  // to set it, rather than an affect unsetting it because the page loaded or something silly
  if (indexer === 'saleMethod') {
    if (!s.saleMethod) {
      // A 'Clear Selection was used'
      delete immerProxy?.auctionFeeSwitches?.applicable;
      if (immerProxy?.contractSpecial?.hideSaleRequired && immerProxy?.contractSpecial?.hideFinance) {
        delete immerProxy.contractSpecial.hideSaleRequired;
        delete immerProxy.contractSpecial.hideFinance;
      }
    }
    if (s.saleMethod === SaleMethod.Auction) {
      if (!immerProxy.auctionFeeSwitches) {
        immerProxy.auctionFeeSwitches = {};
      }
      immerProxy.auctionFeeSwitches.applicable = true;

      if (!immerProxy.contractSpecial) immerProxy.contractSpecial = {};
      immerProxy.contractSpecial.hideSaleRequired = true;
      immerProxy.contractSpecial.hideFinance = true;
    } else if (immerProxy?.contractSpecial?.hideSaleRequired && immerProxy?.contractSpecial?.hideFinance) {
      delete immerProxy.contractSpecial.hideSaleRequired;
      delete immerProxy.contractSpecial.hideFinance;
    }
    // We also need to chain the group function
    setDepositTime(fieldId, updatedPath, immerProxy, variationsSnapshot, history, previousState);
  }

  // We don't accept trash anymore. Too much fussing around for what are clearly intended to be numerical values
  if (typeof s.advertPrcUpper !== 'number') {
    delete s.advertPrcUpper;
  }
  if (typeof s.advertPrc !== 'number') {
    delete s.advertPrc;
  }

  // Before the pmap is set, let's disallow changes to the vendor price in auction on variation
  if (history && history.instanceList.length > 0 && s.vendorPrc && typeof s.vendorPrc === 'number') {
    const instanceGroupings = buildSigningTimelines(history).instanceSets;
    // In case there are no snapshots for some reason, don't attempt to check below
    if (instanceGroupings.length) {
      // Assuming that the latest instance is current. We should not be able to send a variation that
      // has expired for signing, so we should be good
      const currentInstanceSnapshots = instanceGroupings[instanceGroupings.length-1].map(inst => history.data[inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'']);
      const methodPath = `${generateParentPath(updatedPath)}.saleMethod`;
      const anyAuction = s.saleMethod === 'auction' || currentInstanceSnapshots.map(s=>getValueByPath(methodPath, s, true)==='auction').filter(a=>a).length > 0;
      if (anyAuction) {
        const vendorPrcPath = `${generateParentPath(updatedPath)}.vendorPrc`;
        const priceHistory = currentInstanceSnapshots.map(s=>getValueByPath(vendorPrcPath, s, true)).filter(a=>a>0);
        const minPrice = priceHistory.length > 0 ? Math.min(...priceHistory) : null;
        if (minPrice && (s.vendorPrc??0) > minPrice) {
          s.vendorPrc = minPrice;
        }
      }
    } else {
      console.error('No latest signed documents!');
    }

  }

  // pmap: Prescribed Minimum Advertising Price
  const pmap = typeof s.agentEstPrc === 'number' && typeof s.vendorPrc === 'number' && Math.max(s.agentEstPrc, s.vendorPrc);

  if (pmap && iNN(s.advertPrc) && s.advertPrc < pmap) {
    delete s.advertPrc;
  }
  if (pmap && iNN(s.advertPrcUpper) && s.advertPrcUpper < pmap) {
    delete s.advertPrcUpper;
  }

  // The general rule now is that, if the user value becomes invalid, we set it back to unfilled
  // and thus default.
  if (!pmap && ['agentEstPrc', 'vendorPrc'].includes(indexer)) {
    return;
  }

  // Invalidate the other value if it should fall outside the 10% range. Don't set any values

  // We use the Decimal library here to avoid float rounding errors. Don't want there to be legal
  // rammifications because something was ever so slightly more than 10% by using a rougher solution
  if (s.advertPrc && s.advertPrcUpper && (s.advertPrcUpper < s.advertPrc || s.advertPrcUpper > Decimal.mul(s.advertPrc, 1.1).toNumber())) {
    if (indexer === 'advertPrc') delete s.advertPrcUpper;
    if (indexer === 'advertPrcUpper') delete s.advertPrc;
  }

  //Set CGT flag to true if PMAP >= 750k
  pmap && (immerProxy.cgtEnable = pmap >= 750000);
};
