import { PDFDocument } from 'pdf-lib';

export function clearTaggedFields(doc: PDFDocument) {
  const form = doc.getForm();
  for (const field of form.getFields()) {
    if (field.getName().startsWith('@@greatforms-')) {
      form.removeField(field);
    }
  }
}
