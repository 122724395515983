import { MaterialisedPropertyData } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { contractSettlementDefaultEnables } from '../../../../data-and-text/constants';
import { cloneDeep } from 'lodash';

export const setSettlementDisplayDefault: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState, furtherProps) => {
  if (!updatedPath.startsWith('contractSettlement.enabledModes')) return; // We run outputs on other values, but we're not concerned with them changing

  // Get the existing value
  const modes = immerProxy.contractSettlement?.enabledModes;

  // If the existing value isn't even defined, then that's weird and we should quit
  if (!modes) return;
  const previousStateWasAllFalse = Object.values(
    (previousState as MaterialisedPropertyData).contractSettlement?.enabledModes??{}
  ).every(a=>!a);
  // If the previous state was empty, we should overlay the default first and then take the updated value
  if (previousStateWasAllFalse) {
    const asSet = cloneDeep(modes);

    Object.assign(modes, contractSettlementDefaultEnables, asSet);
    return;
  }

  // If all the values are set to false we should probably just set the default
  if (Object.values(modes).every(a=>!a)) {
    Object.assign(modes, contractSettlementDefaultEnables);
    return;
  }
};
