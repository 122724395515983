import { CoverSheetCustomisation, CoverSheetMode, FormInstance } from '@property-folders/contract';

export function ensureCoverSheetCustomisation(value: FormInstance): CoverSheetCustomisation {
  if (!value.cover) {
    value.cover = {
      mode: CoverSheetMode.Standard,
      custom: {}
    };
  }

  if (value.cover.mode == null) {
    value.cover.mode = CoverSheetMode.Standard;
  }

  return value.cover;
}

export function getCoverSheetMode(value?: FormInstance): CoverSheetMode {
  return value?.cover?.mode ?? CoverSheetMode.Standard;
}
