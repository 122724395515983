import { MaterialisedPropertyData } from '@property-folders/contract';
import {
  ComplexValidationContext, ComplexValidationReturnErrors,
  ComplexValidationReturnType
} from '@property-folders/contract/yjs-schema/model/complex-validator';

function log(message?: any, ...optionalParams: any[]): void {
  // if (!ValidationRuntimeContext.enableLogging) return;

  console.log('validateSalespersonStillSalesperson', message, ...optionalParams);
}

const successResponse: ComplexValidationReturnType = { forceUpdatesOn: [], errorObj: {} };

export function validateSalespersonStillSalesperson(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  context?: Pick<ComplexValidationContext, 'entities'>
): ComplexValidationReturnType {
  log('begin', nodeTree, 'entities?', context?.entities);
  if (!context?.entities) return successResponse;

  const allowedKeys = new Set<string>();
  context.entities?.forEach(entity => {
    entity.salespeople?.forEach(sp => {
      if (!sp.isSalesperson) return;
      allowedKeys.add(`${entity.entityId}|${sp.id}`);
    });
  });

  const errorObj: ComplexValidationReturnErrors = {};
  for (const agent of nodeTree.agent ?? []) {
    if (!agent.linkedEntityId) continue;

    for (const salesp of agent.salesp ?? []) {
      if (!salesp.linkedSalespersonId) continue;

      if (!allowedKeys.has(`${agent.linkedEntityId}|${salesp.linkedSalespersonId}`)) {
        errorObj[`agent.[${agent.id}].salesp.[${salesp.id}].name`] = ['no-longer-linked'];
      }
    }
  }

  log('errorObj', errorObj);
  return {
    forceUpdatesOn: [],
    errorObj
  };
}
